body.no-scroll {
    overflow: hidden;
}

.lead-capture-popup {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 100000;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    &.active {
        opacity: 1;
        visibility: visible;
    }
    .error-message {
        font-size: 14px;
        margin-top: 2px;
    }
    input.input-error {
        border: 1px solid red;
        background: rgba(255, 0, 0, 0.1);
    }
    input[type="tel"].input-error:focus {
        border: 1px solid red;
        background: rgba(255, 0, 0, 0.1);
    }
    .lead-capture-popup-opacity {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(2px);
        z-index: 1;
    }
    .lead-capture-popup-container {
        background: yellow;
        padding: 18px 18px 40px;
        background: $neutral_white;
        border-radius: 16px;
        z-index: 2;
        width: 95%;
        max-height: 85dvh;
        overflow: hidden;
        overflow-y: scroll;
        .lead-capture-close {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            svg {
                position: relative;
                width: 20px;
                height: 20px;
            }
        }
        .wrapper {
            display: flex;
            flex-direction: column;
            padding: 0 10px;
            gap: 16px;
            overflow: hidden;
            .thank-you-confirmation {
                text-align: center;
                display: none;
                &.active {
                    display: block;
                }
            }
            .initial-form-content {
                display: flex;
                flex-direction: column;
                padding: 0 10px;
                gap: 16px;
                &.inactive {
                    display: none;
                }
            }
        }
        .primary-header {
            text-align: center;
        }
        .primary-header .heading,
        .secondary-header .heading {
            color: $primary_2;
        }
        .primary-header .subheading,
        .secondary-header .subheading {
            font-weight: 400;
            color: $primary_2;
        }
        .icons {
            display: flex;
            flex-direction: column;
            gap: 6px;
            .icon-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 16px;
                .icon {
                    display: block;
                    min-width: 36px;
                    width: 36px;
                    min-height: 36px;
                    height: 36px;
                }
                .label {
                    width: 90%;
                    font-weight: 400;
                    color: $primary_2;
                }
            }
        }
        .lead-capture-form {
            margin-bottom: 8px;
        }

        .error-msg {
            margin: 0;
        
            p {
                @include fontSize(14px);
                @include lineHeight(16px);
                color: red;
                margin: 0;
            }
        }
        
        .terms {
            > * {
                font-size: 12px;
                line-height: 20px;
                font-style: normal;
                color: #505050;
                font-weight: 400;
            }
            p a {
                color: $primary_2;
            }
        }
    }
}

.lead-capture-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .input-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    label {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: $primary_2;
        text-transform: none;
        padding: 0;
        margin-bottom: 6px;
    }

    input {
        border-radius: 10px;
        padding: 15px 0;
        border: none;
        border: 1px solid #DCDCDC;
        font-family: "Open Sans";
        font-size: 16px;
        color: #505050;
        text-indent: 20px;
    }

    input::placeholder {
        font-style: normal;
        color: #505050;
    }

    input[type="text"] {
        width: 100%;
    }
    
    input[type="tel"]:focus {
        outline: 0 !important;
        background-color: #f7f7f7;
        border-style: solid;
        border-color: #969696;
        border-width: 1px 1px 1px 1px;
    }

    input[type=checkbox] {
        position: relative;
        opacity: 1;
        margin-top: 0;
    }

    .row-1 {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        gap: 24px;

        .input-container {
            display: flex;
            width: 100%;
            position: relative;
        }
    }

    .row-2 {
        .phone-number-input {
            display: flex;
            width: 100%;

            input {
                width: 100%;
                border-radius: 0 10px 10px 0;
            }

            /* Bootstrap override */
            .bootstrap-select .dropdown-toggle, .bootstrap-select.btn-group .dropdown-toggle {
                border: 0;
                padding: 0 12px 0 20px;
                border-radius: 10px 0 0 10px;
                border-top: 1px solid #DCDCDC;
                border-bottom: 1px solid #DCDCDC;
                border-left: 1px solid #DCDCDC;
                font-family: "Open Sans";
                font-size: 16px;
                color: #505050;
                width: 120px;
                height: 100%;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                background: $neutral_white;
            }

            .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
                width: 120px;
            }
        }
    }

    .row-3 {
        input {
            width: 100%;
        }
    }

    .row-4 {
        .row-4-input-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            label {
                margin: 0;
                font-weight: 400;
            }
        }

        #checkbox {
            margin-right: 12px;
        }
    }
}

.lead-capture-cta {
    display: flex;
    align-self: flex-start;
    align-items: center;
    padding: 18px 20px;
    position: relative;
    border-radius: 6px;
    height: 56px;
    background: $primary_4;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover {
        background: darken($primary_4, 20%);
    }

    p {
        color: $neutral_white;
        font-weight: 600;
    }

    .icon {
        position: relative;
        width: 15px;
        height: 12px;
        margin-left: 7px;
        color: $neutral_white;
    }
}

/* HELP BUTTON TRIGGER */
.lead-pop-up-trigger {
    position: fixed;
    max-width: 128px;
    width: 104px;
    left: 12px;
    bottom: 12px;
    padding: 12px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    opacity: 1;
    visibility: visible;
    background: #F7F7F7;
    border-bottom: 1px solid #969696;
    border-right: 1px solid #969696;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 99999;
    &.inactive {
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        background: #efefef;
    }
    .icon-award {
        width: 26px;
        height: 26px;
        path {
            stroke: $primary_4;
        }
    }
    .label {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        font-style: normal;
        font-weight: 700;
    }
}

/* medium breakpoint up */
@media #{$md} {
    .lead-pop-up-trigger {
        right: 47px;
        bottom: 30px;
    }
    .lead-capture-popup {
        .lead-capture-popup-container {
            width: 635px;
            padding: 24px 24px 48px;
            max-height: 95dvh;
            overflow-y: scroll;
            .wrapper {
                padding: 0 32px;
                overflow-y: hidden;
                .initial-form-content {
                    .icons {
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 24px;
                        .icon-container {
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            max-width: 33.333%;
                            width: 33.333%;
                            .icon {
                                min-width: 48px;
                                width: 48px;
                                min-height: 48px;
                                height: 48px;
                            }
                        }
                    }
                    .lead-capture-form {
                        .row-1 {
                            flex-direction: row;
                            .input-container {
                                width: 100%;
                            }
                        }
                        .row-4 {
                            .row-4-input-container {
                                justify-content: flex-start;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }
}