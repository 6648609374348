/**
*
* Health Hub - Homepage feature
*
**/
.homepage-feature {
    width: 100%;
    height: auto;
    position: relative;

    .img-layer {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    picture,
    img {
        display: block;
        width: 100%;
    }

    //Desktop
    @media #{$md} {
        height: 294px;

        img {
            position: relative;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: 100%;
            min-width: 1440px;
        }
    }
}
