.module-hero-background-image {
    position: relative;
    margin: 0 !important;

    .background-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: 50% 50%;

        &:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0,0,0,0.15);
        }
    }

    &.remove-background-darkening {
        .background-image {
            &:after {
                display: none;
            }
        }
    }

    .content {
        height: 440px;
        position: relative;
        text-align: center;

        .caption {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding-bottom: 32px;
            .text-block {
                display: inline-block;
                position: relative;
                padding: 8px;
                margin-top: 68px;
                z-index: 1;
                .text-block-background {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(51, 51, 51, 0.75);
                    z-index: -1;
                }
                .text {
                    color: $neutral_white;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 400;
                    font-family: 'Open Sans', sans-serif;
                }
            }
        }
    }

    h1 {
        margin-bottom: 12px;
        color: $neutral_white;
        line-height: 100%;
    }

    .subtitle {
        margin-bottom: 0;
        color: $neutral_white;
    }

    .information {
        margin-top: 30px;
        color: $neutral_white;

        a {
            color: $neutral_white;

            &:hover,
            &:focus,
            &:active {
                color: $neutral_white;
            }
        }
    }

    .sg-btn {
        margin-top: 16px;
        background: $neutral_white;
        color: $primary_2;
        border-color: $neutral_white;

        &:visited {
            background-color: $neutral_white;
            border-color: $neutral_white;
            color: $primary_2;
            outline: none;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: darken($neutral_white, 10);
            border-color: darken($neutral_white, 10);
            color: $neutral_white;
            outline: none;
        }

        &:active {
            background-color: darken($neutral_white, 20);
            border-color: darken($neutral_white, 20);
            color: $primary_2;
        }

        &:disabled {
            background-color: $neutral_4;
            border-color: $neutral_4;
            color: $primary_2;
            outline: none;
        }
    }

    &.background-primary1 {
        .sg-btn {
            color: $primary_1;
        }
    }

    &.background-primary2 {
        .sg-btn {
            color: $primary_2;
        }
    }

    &.background-primary3 {
        .sg-btn {
            color: $primary_3;
        }
    }

    &.background-primary4 {
        .sg-btn {
            color: $primary_4;
        }
    }

    &.background-primary5 {
        .sg-btn {
            color: $primary_5;
        }
    }

    &.align-center {
        .content {
            text-align: center;
        }
    }

    &.dark-text {
        .content {
            .caption {
                h1 {
                    color: $primary_2;
                }

                .subtitle {
                    color: $primary_2;
                }

                .sg-btn {
                    background: $primary_2;
                    color: $neutral_white;
                    border-color: $primary_2;

                    &:visited {
                        background-color: $primary_2;
                        border-color: $primary_2;
                        color: $neutral_white;
                        outline: none;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: darken($primary_2, 10);
                        border-color: darken($primary_2, 10);
                        color: $neutral_white;
                        outline: none;
                    }

                    &:active {
                        background-color: darken($primary_2, 20);
                        border-color: darken($primary_2, 20);
                        color: $neutral_white;
                    }

                    &:disabled {
                        background-color: $neutral_4;
                        border-color: $neutral_4;
                        color: $neutral_white;
                        outline: none;
                    }
                }
            }
        }
    }

    @media #{$sm} {
        .content {
            height: 500px;
            text-align: left;

            .caption {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                bottom: initial;
                transform: translateY(-50%);
                padding-bottom: 0;
            }
        }

        .background-image {
        }

        h1 {
            margin-bottom: 16px;
        }

        .sg-btn {
            margin-top: 32px;
        }

        &.align-center {
            .content {
                text-align: center;
            }
        }
    }
}
