.module-contact-form {
  label {
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #122868;
    margin-bottom: 6px;
    padding: 0;
    text-transform: none;
  }
  input {
    border-radius: 10px;
    padding: 15px 0;
    border: none;
    border: 1px solid #DCDCDC;
    font-family: "Open Sans";
    font-size: 16px;
    color: #505050;
    text-indent: 20px;
  }
  input::placeholder {
    font-style: normal;
    color: #505050;
  }
  input[type="tel"].input-error:focus {
    border: 1px solid red;
    background: rgba(255, 0, 0, 0.1);
  }
  input[type="tel"]:focus {
    outline: 0 !important;
    background-color: #f7f7f7;
    border-style: solid;
    border-color: #969696;
    border-width: 1px 1px 1px 1px;
  }
  .input-error-message {
    color: red;
    display: none;
    font-size: 14px;
    margin-top: 2px;
  
  }
  .primary-header {
    margin-bottom: 23px;
    .heading {
      margin-bottom: 26px;
    }
    .subheading {
      color: $primary_2;
    }
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .input-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    input[type=checkbox] {
      position: relative;
      opacity: 1;
      margin-top: 0;
      min-width: 24px;
      width: 24px;
      min-height: 24px;
      height: 24px;
    }
    .row-1 {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      input {
        width: 100%;
      }
      .input-container {
        display: flex;
        width: 100%;
        position: relative;
      }
    }
    .row-2 {
      .phone-number-input {
        display: flex;
        width: 100%;
        .bootstrap-select .dropdown-toggle,
        .bootstrap-select.btn-group .dropdown-toggle {
          height: 100%;
          background-color: #f7f7f7;
          border: 1px solid #969696;
          border-radius: 10px 0 0 10px;
        }
        .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
          max-width: 120px;
          width: 120px;
        }
        input {
          width: 100%;
          border-radius: 0 10px 10px 0;
        }
        select {
          border: 0;
          padding: 0 12px 0 20px;
          border-radius: 10px 0 0 10px;
          border-top: 1px solid #DCDCDC;
          border-bottom: 1px solid #DCDCDC;
          border-left: 1px solid #DCDCDC;
          font-family: "Open Sans";
          font-size: 16px;
          color: #505050;
          width: 120px;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M6 9L12 15L18 9" stroke="%23122868" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
          background-repeat: no-repeat;
          background-position-x: 93%;
          background-position-y: 50%;
        }
      }
    }
    .row-3 {
      input {
        width: 100%;
      }
    }
    .row-4 {
      .input-container {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        #checkbox {
          margin-right: 12px;
        }
        label {
          margin: 0;
          padding: 0;
          font-weight: 400;
          text-transform: none;
        }
      }
    }
  }
  .cta-buttons {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    .cta-button {
      display: flex;
      align-self: flex-start;
      align-items: center;
      padding: 18px 20px;
      position: relative;
      border-radius: 6px;
      height: 56px;
      cursor: pointer;
      background: transparent;
      border: 2px solid $primary_4;
      transition: all 0.3s ease-in-out;
      gap: 7px;
      &:first-child {
        background: $primary_4;
        &:hover {
          background: darken($primary_4, 20%);
          border: 2px solid darken($primary_4, 20%);
        }
        p,
        .icon {
          color: #fff;
        }
      }
      .icon-right {
        position: relative;
        width: 15px;
        height: 15px;
        margin-left: 7px;
      }
      p,
      .icon {
        color: $primary_4;
        margin: 0;
        padding: 0;
        font-weight: 600;
      }
    }
  }
  .error-msg {
    margin: 0;

    p {
        @include fontSize(14px);
        @include lineHeight(16px);
        color: red;
        margin: 0;
    }
}
  .terms {
    p {
      &:last-child {
        margin: 0;
        padding: 0;
      }
    }
    a {
      color: #3953A5;
    }
  }
}

/* medium breakpoint up */
@media #{$md} {
  .module-contact-form {
    .form-wrapper {
      .row-1 {
        flex-direction: row;
        .input-container {
          width: 50%;
        }
      }
    }
    .cta-buttons {
      flex-direction: row;
    }
  }
}